import { defineStore } from 'pinia'

export const usePopupStore = defineStore('popup', {
  state: () => {
    return {
      rewards: [],
      showReward: false,
      showFollow: false,
    }
  },
  actions: {
    set(key, value = true) {
      this[key] = value;
    },
    showRewards(rewards) {
      if(Array.isArray(rewards)) {
        this.rewards = rewards;
      } else {
        this.rewards = [{ type: "cash", amount: rewards }];
      }
      this.showReward = true;
    },
  }
})
